import { DataStore } from "@aws-amplify/datastore";
import moment from "moment";
import {
  User,
  Configuration,
  TafMessages,
  Experience,
  Booking,
  Views,
  Transactions,
  Affiliates,
  Coupon,
  Transfer,
} from "../models";
import {
  EMPTY_USER,
  EMPTY_EXPERIENCE,
  EMPTY_BOOKING,
  EMPTY_VIEW,
  EMPTY_TRANSACTION,
  EMPTY_AFFILIATE,
  EMPTY_COUPON,
} from "src/constants";

export const configurationActions = {
  /**
   * Retrieve site wide settings managed through admin panel
   *
   * @returns Configuration details
   */
  getConfiguration: (data) => DataStore.query(Configuration).then((config) => config[0]),
  /**
   * Update site configuration
   *
   * @param {Object} data
   * @returns Update configuration details
   */
  updateConfiguration: (data) =>
    DataStore.query(Configuration, (e) => e.id("eq", data.id)).then((res) =>
      DataStore.save(
        Configuration.copyOf(res[0], (config) => {
          config.amount = data.amount;
          config.isBackgroundCheckEnabled = data.isBackgroundCheckEnabled;
          config.isBackgroundCheckPaymentEnabled = data.isBackgroundCheckPaymentEnabled;
          config.platformFee = data.platformFee;
          config.highCostAlert = data.highCostAlert;
        })
      )
    ),
};

export const userActions = {
  createNewUser: (data) => DataStore.save(new User(Object.assign({}, EMPTY_USER, data))),
  // FIXME(LY): Return first element rather than make consumer do it
  getCurrentUser: ({ email }: { email: string }) => DataStore.query(User, (x) => x.email("eq", `${email}`)),
  getUserByUsername: (data) => DataStore.query(User, (x) => x.username("eq", `${data.username}`)),
  updateUser: (data) =>
    DataStore.query(User, (x) => x.id("eq", `${data.id}`)).then((res) =>
      DataStore.save(
        User.copyOf(res[0], (newUser) => {
          newUser.address = { ...data.address };
          newUser.avatar = data.avatar;
          newUser.bio = data.bio.trim();
          newUser.connectId = data.connectId;
          newUser.dob = moment(data.dob).toISOString();
          newUser.email = data.email;
          newUser.isBusiness = data.isBusiness;
          newUser.firstName =
            data.firstName !== "" ? (data.firstName[0].toUpperCase() + data.firstName.slice(1)).trim() : "";
          newUser.gender = data.gender;
          newUser.businessName = data.businessName;
          newUser.isAdmin = data.isAdmin;
          newUser.isGeneralNewsletterSubscribed = data.isGeneralNewsletterSubscribed;
          newUser.isNotificationEmailSubscribed = data.isNotificationEmailSubscribed;
          newUser.isSalesPromotionSubscribed = data.isSalesPromotionSubscribed;
          newUser.isScout = data.isScout;
          newUser.isSiteNotificationSubscribed = data.isSiteNotificationSubscribed;
          newUser.lastName =
            data.lastName !== "" ? (data.lastName[0].toUpperCase() + data.lastName.slice(1)).trim() : "";
          newUser.phone = data.phone;
          newUser.following = data.following;
          newUser.stripeId = data.stripeId;
          newUser.userId = data.userId;
          newUser.username = data.username.trim();
          newUser.businessUrl = data.businessUrl;
          newUser.authorization = data.authorization;
          newUser.screeningId = data.screeningId;
          newUser.screeningResult = data.screeningResult;
          newUser.sterlingClientId = data.sterlingClientId;
          newUser.completedProfile = data.completedProfile;
          newUser.connectAccountCreation = data.connectAccountCreation;
          newUser.connectAccountVerification = data.connectAccountVerification;
          newUser.createdExperience = data.createdExperience;
          newUser.isInterested = data.isInterested;
          newUser.registrationFeePaid = data.registrationFeePaid;
          newUser.sterlingSubmition = data.sterlingSubmition;
          newUser.screeningECT = data.screeningECT;
          newUser.registrationCompletion = data.registrationCompletion;
          newUser.screeningEmailSent = data.screeningEmailSent;
          newUser.cancelled = data.cancelled;
          newUser.deactivatedByAdmin = data.deactivatedByAdmin;
          newUser.lastLogin = data.lastLogin;
          newUser.favoriteExperiences = data.favoriteExperiences;
          newUser.isReferer = data.isReferer;
          newUser.affiliateId = data.affiliateId;
          newUser.isFinanceAdmin = data.isFinanceAdmin;
          newUser.isCsAdmin = data.isCsAdmin;
          newUser.subFeePaid = data.subFeePaid;
        })
      )
    ),
  getAllUsers: () => DataStore.query(User).then((users) => users),
  getUserById: (data) => DataStore.query(User, (x) => x.id("eq", data.id)),
  tafMessage: (data) => DataStore.save(new TafMessages(data)),
};

export const experienceActions = {
  getAllExperiences: () => DataStore.query(Experience),
  getCreatedExperiences: (data) => DataStore.query(Experience, (e) => e.scoutId("eq", data.id)),
  updateExperience: (data: Experience) => {
    return DataStore.query(Experience, (e) => e.id("eq", data.id)).then((res) =>
      DataStore.save(
        Experience.copyOf(res[0], (newExp) => {
          newExp.accessibility = data.accessibility;
          newExp.placeId = data.placeId;
          newExp.isBusiness = data.isBusiness;
          newExp.businessCount = `${data.businessCount}`;
          newExp.businessRating = `${data.businessRating}`;
          newExp.availabilityNotificationSent = data.availabilityNotificationSent;
          newExp.type = data.type;
          newExp.active = data.active;
          newExp.activityLevel = data.activityLevel;
          newExp.always = data.always;
          newExp.businessUrl = data.businessUrl;
          newExp.cancellationPolicy = data.cancellationPolicy;
          newExp.categories = [...new Set(data.categories)];
          newExp.createdOn = data.createdOn;
          newExp.dates = data.dates;
          newExp.deactivatedByAdmin = data.deactivatedByAdmin;
          newExp.description = data.description;
          newExp.experienceLevel = data.experienceLevel;
          newExp.gpsData = data.gpsData;
          newExp.highlight = data.highlight.trim();
          newExp.images = [...new Set(data.images)];
          newExp.isFeatured = data.isFeatured;
          newExp.isHosted = data.isHosted;
          newExp.itemsProvided = [...new Set(data.itemsProvided)].filter((i) => i !== "");
          newExp.itemsToBring = [...new Set(data.itemsToBring)].filter((i) => i !== "");
          newExp.kebabTitle = data.kebabTitle.trim();
          newExp.location = data.location;
          newExp.leadTime = data.leadTime;
          newExp.refundPolicy = data.refundPolicy;
          newExp.maxParticipants = data.maxParticipants;
          newExp.minAge = data.minAge;
          newExp.price = data.price;
          newExp.rebookPolicy = data.rebookPolicy;
          newExp.scout = data.scout;
          newExp.scoutId = data.scoutId;
          newExp.sku = data.sku;
          newExp.stripeProdId = data.stripeProdId;
          newExp.taxCategory = data.taxCategory;
          newExp.title = data.title;
          newExp.updatedOn = new Date().toISOString();
          newExp.dateRequest = data.dateRequest;
          newExp.adminMessage = data.adminMessage;
        })
      )
    );
  },
  createNewExperience: (data) => DataStore.save(new Experience(Object.assign({}, EMPTY_EXPERIENCE, data))),
  getExperienceById: (data) => DataStore.query(Experience, (e) => e.id("eq", data.id)),
  getExperienceByTitle: (data) => DataStore.query(Experience, (e) => e.kebabTitle("eq", data.kebabTitle)),
};

export const bookingActions = {
  createNewBooking: (data) => DataStore.save(new Booking(Object.assign({}, EMPTY_BOOKING, data))),
  updateBooking: (data) =>
    DataStore.query(Booking, (m) => m.id("eq", data.id)).then((res) =>
      DataStore.save(
        Booking.copyOf(res[0], (newBooking) => {
          newBooking.scouted = data.scouted;
          newBooking.experience = data.experience;
          newBooking.scoutedId = data.scoutedId;
          newBooking.experienceId = data.experienceId;
          newBooking.participants = data.participants;
          newBooking.bookingDate = data.bookingDate;
          newBooking.sessionId = data.sessionId;
          newBooking.amount = data.amount;
          newBooking.paymentIntent = data.paymentIntent;
          newBooking.messaging = data.messaging;
          newBooking.review = data.review;
          newBooking.refundRequested = data.refundRequested;
          newBooking.refundPolicy = data.refundPolicy;
          newBooking.refundId = data.refundId;
          newBooking.scoutCancelled = data.scoutCancelled;
          newBooking.cancelReason = data.cancelReason;
          newBooking.updatedOn = moment().toISOString();
          newBooking.affiliateId = data.affiliateId;
          newBooking.taxCollected = data.taxCollected;
          newBooking.totalPrice = data.totalPrice;
        })
      )
    ),
  getBookingById: (data) => DataStore.query(Booking, (e) => e.id("eq", data.id)),
  getBookingByUser: (data) => DataStore.query(Booking, (e) => e.scoutedId("eq", data.id)),
  getExperienceBookingByUser: (data) =>
    DataStore.query(Booking, (e) => e.scoutedId("eq", data.id).experienceId("eq", data.eid).refundId("eq", "")),
  getBookingByExperience: (data) => DataStore.query(Booking, (e) => e.experienceId("eq", data.id).refundId("eq", "")),
  getAllBookings: () => DataStore.query(Booking),
};

export const viewsActions = {
  newView: (data) => DataStore.save(new Views(Object.assign({}, EMPTY_VIEW, data))),
  updateView: (data) =>
    DataStore.query(Views, (e) => e.experienceId("eq", data.experienceId)).then((res) =>
      DataStore.save(
        Views.copyOf(res[0], (view) => {
          view.experienceId = data.experienceId;
          view.count = data.count;
          view.viewers = data.viewers;
        })
      )
    ),
  getViewsByExperience: (data) => DataStore.query(Views, (e) => e.experienceId("eq", data.experienceId)),
  getAllViews: () => DataStore.query(Views),
};

export const transactionActions = {
  newTransaction: (data) => DataStore.save(new Transactions(Object.assign({}, EMPTY_TRANSACTION, data))),
  updateTransaction: (data) =>
    DataStore.query(Transactions, (e) => e.id("eq", data.id)).then((res) =>
      DataStore.save(
        Transactions.copyOf(res[0], (trans) => {
          trans.experienceId = data.experienceId;
          trans.amount = data.amount;
          trans.location = data.location;
          trans.type = data.type;
          trans.scoutId = data.scoutId;
          trans.scoutedId = data.scoutId;
          trans.categories = data.categories;
          trans.processedOn = data.processedOn;
          trans.refunded = data.refunded;
          trans.applicationFee = data.applicationFee;
          trans.paymentIntent = data.paymentIntent;
          trans.refundId = data.refundId;
          trans.affiliateId = data.affiliateId;
          trans.taxCollected = data.taxCollected;
          trans.affiliateAmount = data.affiliateAmount;
          trans.couponId = data.couponId;
          trans.refundedOn = data.refundedOn;
          trans.checkoutSession = data.checkoutSession;
          trans.subscription = data.subscription;
        })
      )
    ),
  getTransactionByType: (data) => DataStore.query(Transactions, (e) => e.type("eq", data.type)),
  getTransactionByPi: (data) => DataStore.query(Transactions, (e) => e.paymentIntent("eq", data.paymentIntent)),
  getAllTransactions: () => DataStore.query(Transactions),
};

export const affiliateActions = {
  newAffiliate: (data) => DataStore.save(new Affiliates(Object.assign({}, EMPTY_AFFILIATE, data))),
  updateAffiliate: (data) =>
    DataStore.query(Affiliates, (e) => e.id("eq", data.id)).then((res) =>
      DataStore.save(
        Affiliates.copyOf(res[0], (affiliate) => {
          affiliate.userId = data.userId;
          affiliate.code = data.code;
          affiliate.newUsers = data.newUsers;
          affiliate.count = data.count;
        })
      )
    ),
  getAffiliateById: (data) => DataStore.query(Affiliates, (e) => e.id("eq", data.id)),
  getAffiliateByCode: (data) => DataStore.query(Affiliates, (e) => e.code("eq", data.code)),
  getAffiliateByUser: (data) => DataStore.query(Affiliates, (e) => e.userId("eq", data.userId)),
  getAllAffiliates: () => DataStore.query(Affiliates),
};

export const couponActions = {
  newCoupon: (data) => DataStore.save(new Coupon(Object.assign({}, EMPTY_COUPON, data))),
  updateCoupon: (data) =>
    DataStore.query(Coupon, (e) => e.id("eq", data.id)).then((res) =>
      DataStore.save(
        Coupon.copyOf(res[0], (coupon) => {
          coupon.amountOff = data.amountOff;
          coupon.duration = data.duration;
          coupon.durationInMonths = data.durationInMonths;
          coupon.maxRedemptions = data.maxRedemptions;
          coupon.percentOff = data.percentOff;
          coupon.timesRedeemed = data.timesRedeemed;
          coupon.valid = data.valid;
        })
      )
    ),
  getCouponById: (data) => DataStore.query(Coupon, (e) => e.id("eq", data.id)),
  getCouponByStripeId: (data) => DataStore.query(Coupon, (e) => e.stripeId("eq", data.stripeId)),
  getAllCoupons: () => DataStore.query(Coupon),
};

export const transferActions = {
  newTransfer: (data) => DataStore.save(new Transfer(data)),
  updateTransfer: (data) =>
    DataStore.query(Transfer, (e) => e.id("eq", data.id)).then((res) =>
      DataStore.save(
        Transfer.copyOf(res[0], (transfer) => {
          transfer.availableBy = data.availableBy;
          transfer.platformPaid = data.platformPaid;
          transfer.affiliatePaid = data.affiliatePaid;
          transfer.scoutPaid = data.scoutPaid;
          transfer.taxPaid = data.taxPaid;
          transfer.taxAmount = data.taxAmount;
          transfer.affiliateAmount = data.affiliateAmount;
          transfer.affiliateUserId = data.affiliateUserId;
          transfer.scoutAmount = data.scoutAmount;
          transfer.scoutUserId = data.scoutUserId;
          transfer.platformAmount = data.platformAmount;
          transfer.transactionId = data.transactionId;
          transfer.bookingId = data.bookingId;
          transfer.affiliateTransId = data.affiliateTransId;
          transfer.scoutTransId = data.scoutTransId;
          transfer.platformTransId = data.platformTransId;
          transfer.taxTransId = data.taxTransId;
          transfer.refunded = data.refunded;
          transfer.refundedOn = data.refundedOn;
        })
      )
    ),
  getScoutTransfers: (data) =>
    DataStore.query(Transfer, (e) => e.scoutUserId("eq", data.id).scoutPaid("eq", false).refunded("ne", true)),
  getTransferByBookingId: (data) => DataStore.query(Transfer, (e) => e.bookingId("eq", data.id)),
  getAffiliateTransfers: (data) =>
    DataStore.query(Transfer, (e) => e.affiliateUserId("eq", data.id).affiliatePaid("eq", false).refunded("ne", true)),
  getAllTransfers: () => DataStore.query(Transfer),
};
